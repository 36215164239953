<template>
  <div>
    <div class="facilityBox">
      <el-tabs v-model="activeName">
        <el-tab-pane label="未审核" name="1">
          <auditOwner ref="auditOwner" v-if="activeName == 1"></auditOwner>
        </el-tab-pane>
        <el-tab-pane label="已审核" name="2">
          <certifiedOwner
            ref="certifiedOwner"
            v-if="activeName == 2"
          ></certifiedOwner>
        </el-tab-pane>
        <el-tab-pane label="审核失败" name="3">
          <auditOwner
            :searchStatus="'true'"
            ref="auditOwner"
            v-if="activeName == 3"
          ></auditOwner>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import auditOwner from "@/views/platform/auditInfo/auditOwner/noAuditOwner";
import certifiedOwner from "@/views/platform/auditInfo/auditOwner/certifiedOwner";
export default {
  data() {
    return {
      activeName: "1",
    };
  },
  components: {
    auditOwner,
    certifiedOwner,
  },
  watch: {
    activeName(val) {
      setTimeout(() => {
        if (val == 1 || val == 3) {
          this.$refs.auditOwner.GetEnterprise();
        }
        if (val == 2) {
          this.$refs.certifiedOwner.GetEnterprise();
        }
      }, 500);
    },
  },
};
</script>

<style lang="scss" scoped></style>
